import {AppSize} from "../../constant/appSize";

export default function ButtonTwoIcon(
    {
        text,
        color,
        fontSize = 14,
        fontWeight = 600,
        buttonColor,
        horizontal = 4,
        vertical = 3,
        radius = 4,
        isHTMLFirstIcon = true,
        isHTMLSecondIcon = true,
        iconSize,
        classFirstIcon,
        classSecondIcon,
        firstImageSource,
        firstImageAlt,
        secondImageSource,
        secondImageAlt,
        className = "",
        onClick = () => {
        },
        props = {}
    }
) {
    return <button
        onClick={onClick}
        style={{
            backgroundColor: buttonColor,
            color: color,
            fontWeight,
            lineHeight: 1.21,
            fontSize,
            padding: `${vertical * AppSize.basePadding}px ${horizontal * AppSize.basePadding}px`,
            borderRadius: radius * AppSize.baseRadius
        }}
        className={className}
        {...props}
    >{isHTMLFirstIcon ?
        <i className={`${classFirstIcon}`} style={{fontSize: iconSize, color}}></i> :
        <img src={firstImageSource} width={iconSize} alt={firstImageAlt}
             className={"inline-block"}/>} {text} {isHTMLSecondIcon ?
        <i className={`${classSecondIcon}`} style={{fontSize: iconSize, color}}></i> :
        <img src={secondImageSource} width={iconSize} alt={secondImageAlt}
             className={"inline-block"}/>}</button>

}