import AxiosClient from "./axiosClient";
import preferenceKey from "../general/constant/preferenceKey";
import { forEachRight } from "lodash";

const AuthApi = {
    signIn: (params) => {
        const url = "/auth/sign-in"
        return AxiosClient.post(url, params)
    },
    signUp: (params) => {
        const url = "/auth/sign-up"
        return AxiosClient.post(url, params)
    },
    confirm: (params) => {
        const url = "/auth/confirm"
        return AxiosClient.get(url, {params})
    },
    signOut: (params) => {
        const url = "/auth/sign-out"
        return AxiosClient.post(url, params)
    },
    refreshToken: (params) => {
        const url = "/auth/refresh-token"
        return AxiosClient.get(url, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(preferenceKey.refreshToken)
            }
        })
    },
    dataGoogleCallback : (params) => {
        const url = "/auth/google/data"
        return AxiosClient.get(url,{params})
    },
    genOTP : (params) => {
        const url = "/auth/generate-otp"
        return AxiosClient.get(url, {params})
    },
    confirmOTP : (params) => {
        const url = "/auth/confirm-otp"
        return AxiosClient.post(url, params)
    },
    disableOTP : (params) => {
        const url = "/auth/disable-otp"
        return AxiosClient.post(url, params)
    },
    verifyOTP : (params) => {
        const url = "/auth/verify-otp"
        return AxiosClient.post(url, params)
    },
    sendResetOtp : (params) => {
        const url = "/auth/send-reset-otp"
        return AxiosClient.post(url, params)
    },
    resetOTP : (params) => {
        const url = "/auth/reset-otp"
        return AxiosClient.post(url, params)
    },
    forgetPassword : (params) => {
        const url = "/auth/forget-password"
        return AxiosClient.post(url, params)
    },
}

export default AuthApi