export default function EmptyDefault(
    {
                                         text = "Not found"
                                     }
                                     ) {
    return (
        <div className={"w-full h-full flex justify-center items-center"}>
            <h2 className={"text-black-3F leading-normal"}>{text}</h2>
        </div>
    )
}