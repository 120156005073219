import icGoogle from "../../asset/icon/icGoogle.svg"
import icCheck from "../../asset/icon/icCheck.svg"
import icLine from "../../asset/icon/icDeviden.svg"
import icVirtual from "../../asset/icon/icVirtual.svg"
import icVirtualGreen from "../../asset/icon/icVirtualGreen.svg"
import icVirtualLightGreen from "../../asset/icon/icVirtualLightGreen.svg"
import icVirtualCircle from "../../asset/icon/icVirtualCircle.svg"
import icDollar from "../../asset/icon/IconDollar.svg"
import icGrow from "../../asset/icon/icGrow.svg"
import icUser from "../../asset/icon/icUser.svg"
import icFile from "../../asset/icon/icFile.svg"
import ic404 from "../../asset/icon/ic404.svg"
import icAvatarExample from "../../asset/icon/icAvatarExample.svg"
import icDefaultAvatar from "../../asset/icon/icDefaultAvatar.svg"
import icGeneralPlatform from "../../asset/icon/icGeneralPlatform.svg"
import icFacebook from "../../asset/icon/icFacebook.svg"
import icZalo from "../../asset/icon/icZalo.svg"
import icShopee from "../../asset/icon/icShopee.svg"
import icWebsite from "../../asset/icon/icWebsite.svg"
import icUp from "../../asset/icon/icUp.svg"
import icDown from "../../asset/icon/icDown.svg"
import icTiktokBg from "../../asset/icon/icTiktokBg.svg"
import icFacebookBg from "../../asset/icon/icFacebookBg.svg"
import icZaloBg from "../../asset/icon/icZaloBg.svg"
import icMessengerBg from "../../asset/icon/icMessengerBg.svg"
import icWebBg from "../../asset/icon/icWebBg.svg"
import icStart from "../../asset/icon/icStart.svg"
import icCrown from "../../asset/icon/icCrown.svg"
import icDiamond from "../../asset/icon/icDiamon.svg"
import icDiamondYellow from "../../asset/icon/icDiamonYellow.svg"
import icCoin from "../../asset/icon/icCoin.svg"
import icChatbotDefault from "../../asset/icon/icChatbotDefault.svg"
import icUserBg from "../../asset/icon/icUserBg.svg"
import icFlagEN from "../../asset/icon/icFlagEN.svg"
import icFlagVI from "../../asset/icon/icFlagVI.svg"
import icWebsiteComment from "../../asset/icon/icWebsiteComment.svg"
import icAppSaymee from "../../asset/icon/icAppSaymee.svg"


import imgLock from "../../asset/image/imgLock.svg"
import imgExampleAvatar from "../../asset/image/imgExampleAvatar.png"
import imgDefaultAvatar from "../../asset/image/imgDefaultAvatar.svg"
import imgFormatText from "../../asset/image/imgFormatText.svg"
import imgBgVirtualGen from "../../asset/image/imgBgVirtualGen.svg"
import imgNoDataUpload from "../../asset/image/imgNoDataUpload.svg"
import imgNoDataCustomer from "../../asset/image/imgNoDataCustomer.svg"
import imgNoDataChatbot from "../../asset/image/imgNoDataChatbot.svg"
import imgNoDataTransaction from "../../asset/image/imgNoDataTransaction.svg"
import imgNoIntegratePlatform from "../../asset/image/imgNoIntegratePlatform.svg"
import imgNoDataSearch from "../../asset/image/imgNoDataSearch.svg"
import imgErrorFolder from "../../asset/image/imgErrorFolder.png"
import imgBgCategory from "../../asset/image/imgBgCategory.svg"


import soundMessage from "../../asset/sound/soundMessage.mp3"

import vi from "../../asset/language/vi.json"
import en from "../../asset/language/en.json"

export {
    icGoogle,
    icCheck,
    icLine,
    icVirtual,
    icVirtualGreen,
    icVirtualLightGreen,
    icVirtualCircle,
    icDollar,
    icGrow,
    icUser,
    icFile,
    ic404,
    icAvatarExample,
    icDefaultAvatar,
    icGeneralPlatform,
    icFacebook,
    icZalo,
    icShopee,
    icWebsite,
    icUp,
    icDown,
    icWebBg,
    icZaloBg,
    icTiktokBg,
    icMessengerBg,
    icFacebookBg,
    icStart,
    icCrown,
    icDiamond,
    icDiamondYellow,
    icCoin,
    icChatbotDefault,
    icUserBg,
    icFlagEN,
    icFlagVI,
    icWebsiteComment,
    icAppSaymee
}

export {
    imgLock,
    imgDefaultAvatar,
    imgExampleAvatar,
    imgNoDataUpload,
    imgFormatText,
    imgBgVirtualGen,
    imgNoIntegratePlatform,
    imgNoDataCustomer,
    imgNoDataChatbot,
    imgNoDataTransaction,
    imgNoDataSearch,
    imgErrorFolder,
    imgBgCategory
}

export {
    soundMessage
}

export {en, vi}